/*!
 * KaTeX v0.16.9
 * Plus added customizations for the theme and font path
 */
$katex-font-path: "../../assets/fonts/katex" !default;
.katex-display {
  @media screen and (max-width: $sm-break) {
    overflow-x: auto;
  }
  padding-bottom: 3px;
  padding-top: 1px;
  width: auto;
  height: auto;
}

.math-display {
  display: inline-block;
  width: 100%;
}

@import "katex/katex";
