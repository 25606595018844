$font-family_1: KaTeX_Main;
$font-family_2: KaTeX_SansSerif;
$font-family_3: KaTeX_Typewriter;
$font-family_4: KaTeX_Math;
$font-family_5: KaTeX_AMS;
$font-family_6: KaTeX_Caligraphic;
$font-family_7: KaTeX_Fraktur;
$font-family_8: KaTeX_Script;
$font-family_9: KaTeX_Size1;
$font-family_10: KaTeX_Size2;
$font-family_11: KaTeX_Size3;
$font-family_12: KaTeX_Size4;
$border-color_1: currentColor;

@font-face {
  font-family: KaTeX_AMS;
  font-style: normal;
  font-weight: 400;
  src: url("#{$katex-font-path}/KaTeX_AMS-Regular.woff2") format("woff2"),url("#{$katex-font-path}/KaTeX_AMS-Regular.woff") format("woff"),url("#{$katex-font-path}/KaTeX_AMS-Regular.ttf") format("truetype");
}
@font-face {
  font-family: KaTeX_Caligraphic;
  font-style: normal;
  font-weight: 700;
  src: url("#{$katex-font-path}/KaTeX_Caligraphic-Bold.woff2") format("woff2"),url("#{$katex-font-path}/KaTeX_Caligraphic-Bold.woff") format("woff"),url("#{$katex-font-path}/KaTeX_Caligraphic-Bold.ttf") format("truetype");
}
@font-face {
  font-family: KaTeX_Caligraphic;
  font-style: normal;
  font-weight: 400;
  src: url("#{$katex-font-path}/KaTeX_Caligraphic-Regular.woff2") format("woff2"),url("#{$katex-font-path}/KaTeX_Caligraphic-Regular.woff") format("woff"),url("#{$katex-font-path}/KaTeX_Caligraphic-Regular.ttf") format("truetype");
}
@font-face {
  font-family: KaTeX_Fraktur;
  font-style: normal;
  font-weight: 700;
  src: url("#{$katex-font-path}/KaTeX_Fraktur-Bold.woff2") format("woff2"),url("#{$katex-font-path}/KaTeX_Fraktur-Bold.woff") format("woff"),url("#{$katex-font-path}/KaTeX_Fraktur-Bold.ttf") format("truetype");
}
@font-face {
  font-family: KaTeX_Fraktur;
  font-style: normal;
  font-weight: 400;
  src: url("#{$katex-font-path}/KaTeX_Fraktur-Regular.woff2") format("woff2"),url("#{$katex-font-path}/KaTeX_Fraktur-Regular.woff") format("woff"),url("#{$katex-font-path}/KaTeX_Fraktur-Regular.ttf") format("truetype");
}
@font-face {
  font-family: KaTeX_Main;
  font-style: normal;
  font-weight: 700;
  src: url("#{$katex-font-path}/KaTeX_Main-Bold.woff2") format("woff2"),url("#{$katex-font-path}/KaTeX_Main-Bold.woff") format("woff"),url("#{$katex-font-path}/KaTeX_Main-Bold.ttf") format("truetype");
}
@font-face {
  font-family: KaTeX_Main;
  font-style: italic;
  font-weight: 700;
  src: url("#{$katex-font-path}/KaTeX_Main-BoldItalic.woff2") format("woff2"),url("#{$katex-font-path}/KaTeX_Main-BoldItalic.woff") format("woff"),url("#{$katex-font-path}/KaTeX_Main-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: KaTeX_Main;
  font-style: italic;
  font-weight: 400;
  src: url("#{$katex-font-path}/KaTeX_Main-Italic.woff2") format("woff2"),url("#{$katex-font-path}/KaTeX_Main-Italic.woff") format("woff"),url("#{$katex-font-path}/KaTeX_Main-Italic.ttf") format("truetype");
}
@font-face {
  font-family: KaTeX_Main;
  font-style: normal;
  font-weight: 400;
  src: url("#{$katex-font-path}/KaTeX_Main-Regular.woff2") format("woff2"),url("#{$katex-font-path}/KaTeX_Main-Regular.woff") format("woff"),url("#{$katex-font-path}/KaTeX_Main-Regular.ttf") format("truetype");
}
@font-face {
  font-family: KaTeX_Math;
  font-style: italic;
  font-weight: 700;
  src: url("#{$katex-font-path}/KaTeX_Math-BoldItalic.woff2") format("woff2"),url("#{$katex-font-path}/KaTeX_Math-BoldItalic.woff") format("woff"),url("#{$katex-font-path}/KaTeX_Math-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: KaTeX_Math;
  font-style: italic;
  font-weight: 400;
  src: url("#{$katex-font-path}/KaTeX_Math-Italic.woff2") format("woff2"),url("#{$katex-font-path}/KaTeX_Math-Italic.woff") format("woff"),url("#{$katex-font-path}/KaTeX_Math-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "KaTeX_SansSerif";
  font-style: normal;
  font-weight: 700;
  src: url("#{$katex-font-path}/KaTeX_SansSerif-Bold.woff2") format("woff2"),url("#{$katex-font-path}/KaTeX_SansSerif-Bold.woff") format("woff"),url("#{$katex-font-path}/KaTeX_SansSerif-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "KaTeX_SansSerif";
  font-style: italic;
  font-weight: 400;
  src: url("#{$katex-font-path}/KaTeX_SansSerif-Italic.woff2") format("woff2"),url("#{$katex-font-path}/KaTeX_SansSerif-Italic.woff") format("woff"),url("#{$katex-font-path}/KaTeX_SansSerif-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "KaTeX_SansSerif";
  font-style: normal;
  font-weight: 400;
  src: url("#{$katex-font-path}/KaTeX_SansSerif-Regular.woff2") format("woff2"),url("#{$katex-font-path}/KaTeX_SansSerif-Regular.woff") format("woff"),url("#{$katex-font-path}/KaTeX_SansSerif-Regular.ttf") format("truetype");
}
@font-face {
  font-family: KaTeX_Script;
  font-style: normal;
  font-weight: 400;
  src: url("#{$katex-font-path}/KaTeX_Script-Regular.woff2") format("woff2"),url("#{$katex-font-path}/KaTeX_Script-Regular.woff") format("woff"),url("#{$katex-font-path}/KaTeX_Script-Regular.ttf") format("truetype");
}
@font-face {
  font-family: KaTeX_Size1;
  font-style: normal;
  font-weight: 400;
  src: url("#{$katex-font-path}/KaTeX_Size1-Regular.woff2") format("woff2"),url("#{$katex-font-path}/KaTeX_Size1-Regular.woff") format("woff"),url("#{$katex-font-path}/KaTeX_Size1-Regular.ttf") format("truetype");
}
@font-face {
  font-family: KaTeX_Size2;
  font-style: normal;
  font-weight: 400;
  src: url("#{$katex-font-path}/KaTeX_Size2-Regular.woff2") format("woff2"),url("#{$katex-font-path}/KaTeX_Size2-Regular.woff") format("woff"),url("#{$katex-font-path}/KaTeX_Size2-Regular.ttf") format("truetype");
}
@font-face {
  font-family: KaTeX_Size3;
  font-style: normal;
  font-weight: 400;
  src: url("#{$katex-font-path}/KaTeX_Size3-Regular.woff2") format("woff2"),url("#{$katex-font-path}/KaTeX_Size3-Regular.woff") format("woff"),url("#{$katex-font-path}/KaTeX_Size3-Regular.ttf") format("truetype");
}
@font-face {
  font-family: KaTeX_Size4;
  font-style: normal;
  font-weight: 400;
  src: url("#{$katex-font-path}/KaTeX_Size4-Regular.woff2") format("woff2"),url("#{$katex-font-path}/KaTeX_Size4-Regular.woff") format("woff"),url("#{$katex-font-path}/KaTeX_Size4-Regular.ttf") format("truetype");
}
@font-face {
  font-family: KaTeX_Typewriter;
  font-style: normal;
  font-weight: 400;
  src: url("#{$katex-font-path}/KaTeX_Typewriter-Regular.woff2") format("woff2"),url("#{$katex-font-path}/KaTeX_Typewriter-Regular.woff") format("woff"),url("#{$katex-font-path}/KaTeX_Typewriter-Regular.ttf") format("truetype");
}
.katex {
  text-rendering: auto;
  font: normal 1.21em KaTeX_Main,Times New Roman,serif;
  line-height: 1.2;
  text-indent: 0;
  * {
    -ms-high-contrast-adjust: none !important;
    border-color: $border-color_1;
  }
  .katex-version {
    &:after {
      content: "0.16.9";
    }
  }
  .katex-mathml {
    clip: rect(1px,1px,1px,1px);
    border: 0;
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .katex-html {
    >.newline {
      display: block;
    }
  }
  .base {
    position: relative;
    white-space: nowrap;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
    display: inline-block;
  }
  .strut {
    display: inline-block;
  }
  .textbf {
    font-weight: 700;
  }
  .textit {
    font-style: italic;
  }
  .textrm {
    font-family: $font-family_1;
  }
  .textsf {
    font-family: $font-family_2;
  }
  .texttt {
    font-family: $font-family_3;
  }
  .mathnormal {
    font-family: $font-family_4;
    font-style: italic;
  }
  .mathit {
    font-family: $font-family_1;
    font-style: italic;
  }
  .mathrm {
    font-style: normal;
  }
  .mathbf {
    font-family: $font-family_1;
    font-weight: 700;
  }
  .boldsymbol {
    font-family: $font-family_4;
    font-style: italic;
    font-weight: 700;
  }
  .amsrm {
    font-family: $font-family_5;
  }
  .mathbb {
    font-family: $font-family_5;
  }
  .textbb {
    font-family: $font-family_5;
  }
  .mathcal {
    font-family: $font-family_6;
  }
  .mathfrak {
    font-family: $font-family_7;
  }
  .textfrak {
    font-family: $font-family_7;
  }
  .mathboldfrak {
    font-family: $font-family_7;
    font-weight: 700;
  }
  .textboldfrak {
    font-family: $font-family_7;
    font-weight: 700;
  }
  .mathtt {
    font-family: $font-family_3;
  }
  .mathscr {
    font-family: $font-family_8;
  }
  .textscr {
    font-family: $font-family_8;
  }
  .mathsf {
    font-family: $font-family_2;
  }
  .mathboldsf {
    font-family: $font-family_2;
    font-weight: 700;
  }
  .textboldsf {
    font-family: $font-family_2;
    font-weight: 700;
  }
  .mathitsf {
    font-family: $font-family_2;
    font-style: italic;
  }
  .textitsf {
    font-family: $font-family_2;
    font-style: italic;
  }
  .mainrm {
    font-family: $font-family_1;
    font-style: normal;
  }
  .vlist-t {
    border-collapse: collapse;
    display: inline-table;
    table-layout: fixed;
  }
  .vlist-r {
    display: table-row;
  }
  .vlist {
    display: table-cell;
    position: relative;
    vertical-align: bottom;
    >span {
      display: block;
      height: 0;
      position: relative;
      >span {
        display: inline-block;
      }
      >.pstrut {
        overflow: hidden;
        width: 0;
      }
    }
  }
  .vlist-t2 {
    margin-right: -2px;
  }
  .vlist-s {
    display: table-cell;
    font-size: 1px;
    min-width: 2px;
    vertical-align: bottom;
    width: 2px;
  }
  .vbox {
    align-items: baseline;
    display: inline-flex;
    flex-direction: column;
  }
  .hbox {
    width: 100%;
    display: inline-flex;
    flex-direction: row;
  }
  .thinbox {
    display: inline-flex;
    flex-direction: row;
    max-width: 0;
    width: 0;
  }
  .msupsub {
    text-align: left;
  }
  .mfrac {
    >span {
      >span {
        text-align: center;
      }
    }
    .frac-line {
      border-bottom-style: solid;
      display: inline-block;
      width: 100%;
      min-height: 1px;
    }
  }
  .hdashline {
    min-height: 1px;
    border-bottom-style: dashed;
    display: inline-block;
    width: 100%;
  }
  .hline {
    min-height: 1px;
    border-bottom-style: solid;
    display: inline-block;
    width: 100%;
  }
  .overline {
    .overline-line {
      min-height: 1px;
      border-bottom-style: solid;
      display: inline-block;
      width: 100%;
    }
  }
  .rule {
    min-height: 1px;
    border: 0 solid;
    display: inline-block;
    position: relative;
  }
  .underline {
    .underline-line {
      min-height: 1px;
      border-bottom-style: solid;
      display: inline-block;
      width: 100%;
    }
  }
  .mspace {
    display: inline-block;
  }
  .clap {
    position: relative;
    width: 0;
    >.inner {
      position: absolute;
      left: 0;
      >span {
        margin-left: -50%;
        margin-right: 50%;
      }
    }
    >.fix {
      display: inline-block;
    }
  }
  .llap {
    position: relative;
    width: 0;
    >.inner {
      position: absolute;
      right: 0;
    }
    >.fix {
      display: inline-block;
    }
  }
  .rlap {
    position: relative;
    width: 0;
    >.inner {
      position: absolute;
      left: 0;
    }
    >.fix {
      display: inline-block;
    }
  }
  .sqrt {
    >.root {
      margin-left: .27777778em;
      margin-right: -.55555556em;
    }
  }
  .fontsize-ensurer.reset-size1.size1 {
    font-size: 1em;
  }
  .sizing.reset-size1.size1 {
    font-size: 1em;
  }
  .fontsize-ensurer.reset-size1.size2 {
    font-size: 1.2em;
  }
  .sizing.reset-size1.size2 {
    font-size: 1.2em;
  }
  .fontsize-ensurer.reset-size1.size3 {
    font-size: 1.4em;
  }
  .sizing.reset-size1.size3 {
    font-size: 1.4em;
  }
  .fontsize-ensurer.reset-size1.size4 {
    font-size: 1.6em;
  }
  .sizing.reset-size1.size4 {
    font-size: 1.6em;
  }
  .fontsize-ensurer.reset-size1.size5 {
    font-size: 1.8em;
  }
  .sizing.reset-size1.size5 {
    font-size: 1.8em;
  }
  .fontsize-ensurer.reset-size1.size6 {
    font-size: 2em;
  }
  .sizing.reset-size1.size6 {
    font-size: 2em;
  }
  .fontsize-ensurer.reset-size1.size7 {
    font-size: 2.4em;
  }
  .sizing.reset-size1.size7 {
    font-size: 2.4em;
  }
  .fontsize-ensurer.reset-size1.size8 {
    font-size: 2.88em;
  }
  .sizing.reset-size1.size8 {
    font-size: 2.88em;
  }
  .fontsize-ensurer.reset-size1.size9 {
    font-size: 3.456em;
  }
  .sizing.reset-size1.size9 {
    font-size: 3.456em;
  }
  .fontsize-ensurer.reset-size1.size10 {
    font-size: 4.148em;
  }
  .sizing.reset-size1.size10 {
    font-size: 4.148em;
  }
  .fontsize-ensurer.reset-size1.size11 {
    font-size: 4.976em;
  }
  .sizing.reset-size1.size11 {
    font-size: 4.976em;
  }
  .fontsize-ensurer.reset-size2.size1 {
    font-size: .83333333em;
  }
  .sizing.reset-size2.size1 {
    font-size: .83333333em;
  }
  .fontsize-ensurer.reset-size2.size2 {
    font-size: 1em;
  }
  .sizing.reset-size2.size2 {
    font-size: 1em;
  }
  .fontsize-ensurer.reset-size2.size3 {
    font-size: 1.16666667em;
  }
  .sizing.reset-size2.size3 {
    font-size: 1.16666667em;
  }
  .fontsize-ensurer.reset-size2.size4 {
    font-size: 1.33333333em;
  }
  .sizing.reset-size2.size4 {
    font-size: 1.33333333em;
  }
  .fontsize-ensurer.reset-size2.size5 {
    font-size: 1.5em;
  }
  .sizing.reset-size2.size5 {
    font-size: 1.5em;
  }
  .fontsize-ensurer.reset-size2.size6 {
    font-size: 1.66666667em;
  }
  .sizing.reset-size2.size6 {
    font-size: 1.66666667em;
  }
  .fontsize-ensurer.reset-size2.size7 {
    font-size: 2em;
  }
  .sizing.reset-size2.size7 {
    font-size: 2em;
  }
  .fontsize-ensurer.reset-size2.size8 {
    font-size: 2.4em;
  }
  .sizing.reset-size2.size8 {
    font-size: 2.4em;
  }
  .fontsize-ensurer.reset-size2.size9 {
    font-size: 2.88em;
  }
  .sizing.reset-size2.size9 {
    font-size: 2.88em;
  }
  .fontsize-ensurer.reset-size2.size10 {
    font-size: 3.45666667em;
  }
  .sizing.reset-size2.size10 {
    font-size: 3.45666667em;
  }
  .fontsize-ensurer.reset-size2.size11 {
    font-size: 4.14666667em;
  }
  .sizing.reset-size2.size11 {
    font-size: 4.14666667em;
  }
  .fontsize-ensurer.reset-size3.size1 {
    font-size: .71428571em;
  }
  .sizing.reset-size3.size1 {
    font-size: .71428571em;
  }
  .fontsize-ensurer.reset-size3.size2 {
    font-size: .85714286em;
  }
  .sizing.reset-size3.size2 {
    font-size: .85714286em;
  }
  .fontsize-ensurer.reset-size3.size3 {
    font-size: 1em;
  }
  .sizing.reset-size3.size3 {
    font-size: 1em;
  }
  .fontsize-ensurer.reset-size3.size4 {
    font-size: 1.14285714em;
  }
  .sizing.reset-size3.size4 {
    font-size: 1.14285714em;
  }
  .fontsize-ensurer.reset-size3.size5 {
    font-size: 1.28571429em;
  }
  .sizing.reset-size3.size5 {
    font-size: 1.28571429em;
  }
  .fontsize-ensurer.reset-size3.size6 {
    font-size: 1.42857143em;
  }
  .sizing.reset-size3.size6 {
    font-size: 1.42857143em;
  }
  .fontsize-ensurer.reset-size3.size7 {
    font-size: 1.71428571em;
  }
  .sizing.reset-size3.size7 {
    font-size: 1.71428571em;
  }
  .fontsize-ensurer.reset-size3.size8 {
    font-size: 2.05714286em;
  }
  .sizing.reset-size3.size8 {
    font-size: 2.05714286em;
  }
  .fontsize-ensurer.reset-size3.size9 {
    font-size: 2.46857143em;
  }
  .sizing.reset-size3.size9 {
    font-size: 2.46857143em;
  }
  .fontsize-ensurer.reset-size3.size10 {
    font-size: 2.96285714em;
  }
  .sizing.reset-size3.size10 {
    font-size: 2.96285714em;
  }
  .fontsize-ensurer.reset-size3.size11 {
    font-size: 3.55428571em;
  }
  .sizing.reset-size3.size11 {
    font-size: 3.55428571em;
  }
  .fontsize-ensurer.reset-size4.size1 {
    font-size: .625em;
  }
  .sizing.reset-size4.size1 {
    font-size: .625em;
  }
  .fontsize-ensurer.reset-size4.size2 {
    font-size: .75em;
  }
  .sizing.reset-size4.size2 {
    font-size: .75em;
  }
  .fontsize-ensurer.reset-size4.size3 {
    font-size: .875em;
  }
  .sizing.reset-size4.size3 {
    font-size: .875em;
  }
  .fontsize-ensurer.reset-size4.size4 {
    font-size: 1em;
  }
  .sizing.reset-size4.size4 {
    font-size: 1em;
  }
  .fontsize-ensurer.reset-size4.size5 {
    font-size: 1.125em;
  }
  .sizing.reset-size4.size5 {
    font-size: 1.125em;
  }
  .fontsize-ensurer.reset-size4.size6 {
    font-size: 1.25em;
  }
  .sizing.reset-size4.size6 {
    font-size: 1.25em;
  }
  .fontsize-ensurer.reset-size4.size7 {
    font-size: 1.5em;
  }
  .sizing.reset-size4.size7 {
    font-size: 1.5em;
  }
  .fontsize-ensurer.reset-size4.size8 {
    font-size: 1.8em;
  }
  .sizing.reset-size4.size8 {
    font-size: 1.8em;
  }
  .fontsize-ensurer.reset-size4.size9 {
    font-size: 2.16em;
  }
  .sizing.reset-size4.size9 {
    font-size: 2.16em;
  }
  .fontsize-ensurer.reset-size4.size10 {
    font-size: 2.5925em;
  }
  .sizing.reset-size4.size10 {
    font-size: 2.5925em;
  }
  .fontsize-ensurer.reset-size4.size11 {
    font-size: 3.11em;
  }
  .sizing.reset-size4.size11 {
    font-size: 3.11em;
  }
  .fontsize-ensurer.reset-size5.size1 {
    font-size: .55555556em;
  }
  .sizing.reset-size5.size1 {
    font-size: .55555556em;
  }
  .fontsize-ensurer.reset-size5.size2 {
    font-size: .66666667em;
  }
  .sizing.reset-size5.size2 {
    font-size: .66666667em;
  }
  .fontsize-ensurer.reset-size5.size3 {
    font-size: .77777778em;
  }
  .sizing.reset-size5.size3 {
    font-size: .77777778em;
  }
  .fontsize-ensurer.reset-size5.size4 {
    font-size: .88888889em;
  }
  .sizing.reset-size5.size4 {
    font-size: .88888889em;
  }
  .fontsize-ensurer.reset-size5.size5 {
    font-size: 1em;
  }
  .sizing.reset-size5.size5 {
    font-size: 1em;
  }
  .fontsize-ensurer.reset-size5.size6 {
    font-size: 1.11111111em;
  }
  .sizing.reset-size5.size6 {
    font-size: 1.11111111em;
  }
  .fontsize-ensurer.reset-size5.size7 {
    font-size: 1.33333333em;
  }
  .sizing.reset-size5.size7 {
    font-size: 1.33333333em;
  }
  .fontsize-ensurer.reset-size5.size8 {
    font-size: 1.6em;
  }
  .sizing.reset-size5.size8 {
    font-size: 1.6em;
  }
  .fontsize-ensurer.reset-size5.size9 {
    font-size: 1.92em;
  }
  .sizing.reset-size5.size9 {
    font-size: 1.92em;
  }
  .fontsize-ensurer.reset-size5.size10 {
    font-size: 2.30444444em;
  }
  .sizing.reset-size5.size10 {
    font-size: 2.30444444em;
  }
  .fontsize-ensurer.reset-size5.size11 {
    font-size: 2.76444444em;
  }
  .sizing.reset-size5.size11 {
    font-size: 2.76444444em;
  }
  .fontsize-ensurer.reset-size6.size1 {
    font-size: .5em;
  }
  .sizing.reset-size6.size1 {
    font-size: .5em;
  }
  .fontsize-ensurer.reset-size6.size2 {
    font-size: .6em;
  }
  .sizing.reset-size6.size2 {
    font-size: .6em;
  }
  .fontsize-ensurer.reset-size6.size3 {
    font-size: .7em;
  }
  .sizing.reset-size6.size3 {
    font-size: .7em;
  }
  .fontsize-ensurer.reset-size6.size4 {
    font-size: .8em;
  }
  .sizing.reset-size6.size4 {
    font-size: .8em;
  }
  .fontsize-ensurer.reset-size6.size5 {
    font-size: .9em;
  }
  .sizing.reset-size6.size5 {
    font-size: .9em;
  }
  .fontsize-ensurer.reset-size6.size6 {
    font-size: 1em;
  }
  .sizing.reset-size6.size6 {
    font-size: 1em;
  }
  .fontsize-ensurer.reset-size6.size7 {
    font-size: 1.2em;
  }
  .sizing.reset-size6.size7 {
    font-size: 1.2em;
  }
  .fontsize-ensurer.reset-size6.size8 {
    font-size: 1.44em;
  }
  .sizing.reset-size6.size8 {
    font-size: 1.44em;
  }
  .fontsize-ensurer.reset-size6.size9 {
    font-size: 1.728em;
  }
  .sizing.reset-size6.size9 {
    font-size: 1.728em;
  }
  .fontsize-ensurer.reset-size6.size10 {
    font-size: 2.074em;
  }
  .sizing.reset-size6.size10 {
    font-size: 2.074em;
  }
  .fontsize-ensurer.reset-size6.size11 {
    font-size: 2.488em;
  }
  .sizing.reset-size6.size11 {
    font-size: 2.488em;
  }
  .fontsize-ensurer.reset-size7.size1 {
    font-size: .41666667em;
  }
  .sizing.reset-size7.size1 {
    font-size: .41666667em;
  }
  .fontsize-ensurer.reset-size7.size2 {
    font-size: .5em;
  }
  .sizing.reset-size7.size2 {
    font-size: .5em;
  }
  .fontsize-ensurer.reset-size7.size3 {
    font-size: .58333333em;
  }
  .sizing.reset-size7.size3 {
    font-size: .58333333em;
  }
  .fontsize-ensurer.reset-size7.size4 {
    font-size: .66666667em;
  }
  .sizing.reset-size7.size4 {
    font-size: .66666667em;
  }
  .fontsize-ensurer.reset-size7.size5 {
    font-size: .75em;
  }
  .sizing.reset-size7.size5 {
    font-size: .75em;
  }
  .fontsize-ensurer.reset-size7.size6 {
    font-size: .83333333em;
  }
  .sizing.reset-size7.size6 {
    font-size: .83333333em;
  }
  .fontsize-ensurer.reset-size7.size7 {
    font-size: 1em;
  }
  .sizing.reset-size7.size7 {
    font-size: 1em;
  }
  .fontsize-ensurer.reset-size7.size8 {
    font-size: 1.2em;
  }
  .sizing.reset-size7.size8 {
    font-size: 1.2em;
  }
  .fontsize-ensurer.reset-size7.size9 {
    font-size: 1.44em;
  }
  .sizing.reset-size7.size9 {
    font-size: 1.44em;
  }
  .fontsize-ensurer.reset-size7.size10 {
    font-size: 1.72833333em;
  }
  .sizing.reset-size7.size10 {
    font-size: 1.72833333em;
  }
  .fontsize-ensurer.reset-size7.size11 {
    font-size: 2.07333333em;
  }
  .sizing.reset-size7.size11 {
    font-size: 2.07333333em;
  }
  .fontsize-ensurer.reset-size8.size1 {
    font-size: .34722222em;
  }
  .sizing.reset-size8.size1 {
    font-size: .34722222em;
  }
  .fontsize-ensurer.reset-size8.size2 {
    font-size: .41666667em;
  }
  .sizing.reset-size8.size2 {
    font-size: .41666667em;
  }
  .fontsize-ensurer.reset-size8.size3 {
    font-size: .48611111em;
  }
  .sizing.reset-size8.size3 {
    font-size: .48611111em;
  }
  .fontsize-ensurer.reset-size8.size4 {
    font-size: .55555556em;
  }
  .sizing.reset-size8.size4 {
    font-size: .55555556em;
  }
  .fontsize-ensurer.reset-size8.size5 {
    font-size: .625em;
  }
  .sizing.reset-size8.size5 {
    font-size: .625em;
  }
  .fontsize-ensurer.reset-size8.size6 {
    font-size: .69444444em;
  }
  .sizing.reset-size8.size6 {
    font-size: .69444444em;
  }
  .fontsize-ensurer.reset-size8.size7 {
    font-size: .83333333em;
  }
  .sizing.reset-size8.size7 {
    font-size: .83333333em;
  }
  .fontsize-ensurer.reset-size8.size8 {
    font-size: 1em;
  }
  .sizing.reset-size8.size8 {
    font-size: 1em;
  }
  .fontsize-ensurer.reset-size8.size9 {
    font-size: 1.2em;
  }
  .sizing.reset-size8.size9 {
    font-size: 1.2em;
  }
  .fontsize-ensurer.reset-size8.size10 {
    font-size: 1.44027778em;
  }
  .sizing.reset-size8.size10 {
    font-size: 1.44027778em;
  }
  .fontsize-ensurer.reset-size8.size11 {
    font-size: 1.72777778em;
  }
  .sizing.reset-size8.size11 {
    font-size: 1.72777778em;
  }
  .fontsize-ensurer.reset-size9.size1 {
    font-size: .28935185em;
  }
  .sizing.reset-size9.size1 {
    font-size: .28935185em;
  }
  .fontsize-ensurer.reset-size9.size2 {
    font-size: .34722222em;
  }
  .sizing.reset-size9.size2 {
    font-size: .34722222em;
  }
  .fontsize-ensurer.reset-size9.size3 {
    font-size: .40509259em;
  }
  .sizing.reset-size9.size3 {
    font-size: .40509259em;
  }
  .fontsize-ensurer.reset-size9.size4 {
    font-size: .46296296em;
  }
  .sizing.reset-size9.size4 {
    font-size: .46296296em;
  }
  .fontsize-ensurer.reset-size9.size5 {
    font-size: .52083333em;
  }
  .sizing.reset-size9.size5 {
    font-size: .52083333em;
  }
  .fontsize-ensurer.reset-size9.size6 {
    font-size: .5787037em;
  }
  .sizing.reset-size9.size6 {
    font-size: .5787037em;
  }
  .fontsize-ensurer.reset-size9.size7 {
    font-size: .69444444em;
  }
  .sizing.reset-size9.size7 {
    font-size: .69444444em;
  }
  .fontsize-ensurer.reset-size9.size8 {
    font-size: .83333333em;
  }
  .sizing.reset-size9.size8 {
    font-size: .83333333em;
  }
  .fontsize-ensurer.reset-size9.size9 {
    font-size: 1em;
  }
  .sizing.reset-size9.size9 {
    font-size: 1em;
  }
  .fontsize-ensurer.reset-size9.size10 {
    font-size: 1.20023148em;
  }
  .sizing.reset-size9.size10 {
    font-size: 1.20023148em;
  }
  .fontsize-ensurer.reset-size9.size11 {
    font-size: 1.43981481em;
  }
  .sizing.reset-size9.size11 {
    font-size: 1.43981481em;
  }
  .fontsize-ensurer.reset-size10.size1 {
    font-size: .24108004em;
  }
  .sizing.reset-size10.size1 {
    font-size: .24108004em;
  }
  .fontsize-ensurer.reset-size10.size2 {
    font-size: .28929605em;
  }
  .sizing.reset-size10.size2 {
    font-size: .28929605em;
  }
  .fontsize-ensurer.reset-size10.size3 {
    font-size: .33751205em;
  }
  .sizing.reset-size10.size3 {
    font-size: .33751205em;
  }
  .fontsize-ensurer.reset-size10.size4 {
    font-size: .38572806em;
  }
  .sizing.reset-size10.size4 {
    font-size: .38572806em;
  }
  .fontsize-ensurer.reset-size10.size5 {
    font-size: .43394407em;
  }
  .sizing.reset-size10.size5 {
    font-size: .43394407em;
  }
  .fontsize-ensurer.reset-size10.size6 {
    font-size: .48216008em;
  }
  .sizing.reset-size10.size6 {
    font-size: .48216008em;
  }
  .fontsize-ensurer.reset-size10.size7 {
    font-size: .57859209em;
  }
  .sizing.reset-size10.size7 {
    font-size: .57859209em;
  }
  .fontsize-ensurer.reset-size10.size8 {
    font-size: .69431051em;
  }
  .sizing.reset-size10.size8 {
    font-size: .69431051em;
  }
  .fontsize-ensurer.reset-size10.size9 {
    font-size: .83317261em;
  }
  .sizing.reset-size10.size9 {
    font-size: .83317261em;
  }
  .fontsize-ensurer.reset-size10.size10 {
    font-size: 1em;
  }
  .sizing.reset-size10.size10 {
    font-size: 1em;
  }
  .fontsize-ensurer.reset-size10.size11 {
    font-size: 1.19961427em;
  }
  .sizing.reset-size10.size11 {
    font-size: 1.19961427em;
  }
  .fontsize-ensurer.reset-size11.size1 {
    font-size: .20096463em;
  }
  .sizing.reset-size11.size1 {
    font-size: .20096463em;
  }
  .fontsize-ensurer.reset-size11.size2 {
    font-size: .24115756em;
  }
  .sizing.reset-size11.size2 {
    font-size: .24115756em;
  }
  .fontsize-ensurer.reset-size11.size3 {
    font-size: .28135048em;
  }
  .sizing.reset-size11.size3 {
    font-size: .28135048em;
  }
  .fontsize-ensurer.reset-size11.size4 {
    font-size: .32154341em;
  }
  .sizing.reset-size11.size4 {
    font-size: .32154341em;
  }
  .fontsize-ensurer.reset-size11.size5 {
    font-size: .36173633em;
  }
  .sizing.reset-size11.size5 {
    font-size: .36173633em;
  }
  .fontsize-ensurer.reset-size11.size6 {
    font-size: .40192926em;
  }
  .sizing.reset-size11.size6 {
    font-size: .40192926em;
  }
  .fontsize-ensurer.reset-size11.size7 {
    font-size: .48231511em;
  }
  .sizing.reset-size11.size7 {
    font-size: .48231511em;
  }
  .fontsize-ensurer.reset-size11.size8 {
    font-size: .57877814em;
  }
  .sizing.reset-size11.size8 {
    font-size: .57877814em;
  }
  .fontsize-ensurer.reset-size11.size9 {
    font-size: .69453376em;
  }
  .sizing.reset-size11.size9 {
    font-size: .69453376em;
  }
  .fontsize-ensurer.reset-size11.size10 {
    font-size: .83360129em;
  }
  .sizing.reset-size11.size10 {
    font-size: .83360129em;
  }
  .fontsize-ensurer.reset-size11.size11 {
    font-size: 1em;
  }
  .sizing.reset-size11.size11 {
    font-size: 1em;
  }
  .delimsizing.size1 {
    font-family: $font-family_9;
  }
  .delimsizing.size2 {
    font-family: $font-family_10;
  }
  .delimsizing.size3 {
    font-family: $font-family_11;
  }
  .delimsizing.size4 {
    font-family: $font-family_12;
  }
  .delimsizing.mult {
    .delim-size1 {
      >span {
        font-family: $font-family_9;
      }
    }
    .delim-size4 {
      >span {
        font-family: $font-family_12;
      }
    }
  }
  .nulldelimiter {
    display: inline-block;
    width: .12em;
  }
  .delimcenter {
    position: relative;
  }
  .op-symbol {
    position: relative;
  }
  .op-symbol.small-op {
    font-family: $font-family_9;
  }
  .op-symbol.large-op {
    font-family: $font-family_10;
  }
  .accent {
    >.vlist-t {
      text-align: center;
    }
    .accent-body {
      position: relative;
      &:not(.accent-full) {
        width: 0;
      }
    }
  }
  .op-limits {
    >.vlist-t {
      text-align: center;
    }
  }
  .overlay {
    display: block;
  }
  .mtable {
    .vertical-separator {
      display: inline-block;
      min-width: 1px;
    }
    .arraycolsep {
      display: inline-block;
    }
    .col-align-c {
      >.vlist-t {
        text-align: center;
      }
    }
    .col-align-l {
      >.vlist-t {
        text-align: left;
      }
    }
    .col-align-r {
      >.vlist-t {
        text-align: right;
      }
    }
  }
  .svg-align {
    text-align: left;
  }
  svg {
    fill: currentColor;
    stroke: currentColor;
    fill-rule: nonzero;
    fill-opacity: 1;
    stroke-width: 1;
    stroke-linecap: butt;
    stroke-linejoin: miter;
    stroke-miterlimit: 4;
    stroke-dasharray: none;
    stroke-dashoffset: 0;
    stroke-opacity: 1;
    display: block;
    height: inherit;
    position: absolute;
    width: 100%;
    path {
      stroke: none;
    }
  }
  img {
    border-style: none;
    max-height: none;
    max-width: none;
    min-height: 0;
    min-width: 0;
  }
  .stretchy {
    display: block;
    overflow: hidden;
    position: relative;
    width: 100%;
    &:after {
      content: "";
    }
    &:before {
      content: "";
    }
  }
  .hide-tail {
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  .halfarrow-left {
    left: 0;
    overflow: hidden;
    position: absolute;
    width: 50.2%;
  }
  .halfarrow-right {
    overflow: hidden;
    position: absolute;
    right: 0;
    width: 50.2%;
  }
  .brace-left {
    left: 0;
    overflow: hidden;
    position: absolute;
    width: 25.1%;
  }
  .brace-center {
    left: 25%;
    overflow: hidden;
    position: absolute;
    width: 50%;
  }
  .brace-right {
    overflow: hidden;
    position: absolute;
    right: 0;
    width: 25.1%;
  }
  .x-arrow-pad {
    padding: 0 .5em;
  }
  .cd-arrow-pad {
    padding: 0 .55556em 0 .27778em;
  }
  .mover {
    text-align: center;
  }
  .munder {
    text-align: center;
  }
  .x-arrow {
    text-align: center;
  }
  .boxpad {
    padding: 0 .3em;
  }
  .fbox {
    border: .04em solid;
    box-sizing: border-box;
  }
  .fcolorbox {
    border: .04em solid;
    box-sizing: border-box;
  }
  .cancel-pad {
    padding: 0 .2em;
  }
  .cancel-lap {
    margin-left: -.2em;
    margin-right: -.2em;
  }
  .sout {
    border-bottom-style: solid;
    border-bottom-width: .08em;
  }
  .angl {
    border-right: .049em solid;
    border-top: .049em solid;
    box-sizing: border-box;
    margin-right: .03889em;
  }
  .anglpad {
    padding: 0 .03889em;
  }
  .eqn-num {
    &:before {
      content: "(" counter(katexEqnNo) ")";
      counter-increment: katexEqnNo;
    }
  }
  .mml-eqn-num {
    &:before {
      content: "(" counter(mmlEqnNo) ")";
      counter-increment: mmlEqnNo;
    }
  }
  .mtr-glue {
    width: 50%;
  }
  .cd-vert-arrow {
    display: inline-block;
    position: relative;
  }
  .cd-label-left {
    display: inline-block;
    position: absolute;
    right: calc(50% + .3em);
    text-align: left;
  }
  .cd-label-right {
    display: inline-block;
    left: calc(50% + .3em);
    position: absolute;
    text-align: right;
  }
}
.katex-display {
  display: block;
  margin: 1em 0;
  text-align: center;
  >.katex {
    display: block;
    text-align: center;
    white-space: nowrap;
    >.katex-html {
      display: block;
      position: relative;
      >.tag {
        position: absolute;
        right: 0;
      }
    }
  }
}
.katex-display.leqno {
  >.katex {
    >.katex-html {
      >.tag {
        left: 0;
        right: auto;
      }
    }
  }
}
.katex-display.fleqn {
  >.katex {
    padding-left: 2em;
    text-align: left;
  }
}
body {
  counter-reset: katexEqnNo mmlEqnNo;
}
