/*!
 * Type-on-strap Font Awesome kit v6.5.1
 * Find the version and license of the included Font Awesome here: _sass/external/font-awesome/fontawesome.scss
 */
$fa-inverse: var(--background);
$fa-font-path: "../fonts/font-awesome" !default;

@import 'font-awesome/fontawesome';
@import 'font-awesome/brands';
@import 'font-awesome/regular';
@import 'font-awesome/solid';
@import 'font-awesome/v4-shims';
