ul.share-buttons {
  list-style: none;
  padding: 1.5% 0 0.75% 0;
  margin: 0;
  text-align: center;

  li.meta {
    display: inline;
    margin-right: 0.5em;
    vertical-align: super;
  }

  li {
    display: inline;

    a:hover {
      text-decoration: none;
    }
  }

  .sr-only {
    position: absolute;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
  }

  .fa-stack {
    vertical-align: top;
    width: 1.75em;

    .fab {
      font-size: 30px;
    }
  }
}
